import * as React from 'react'
import { Link, graphql } from 'gatsby'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import ShopLayout from '../layouts/Shop'
import CtaLink from '../components/CtaLink'
import ProductList from '../components/shop/ProductList'

const Shop = props => {
  const products = props.data.allProductsJson.edges.map(p => p.node)

  return (
    <ShopLayout>
      <Page>
        <Container>
          <h1>NEU: Online Shop</h1>
          <p>
            <CtaLink to="/infos">Infos zu Abholung und Bezahlung</CtaLink>
          </p>
          <p>Unser Produkte:</p>
          <ProductList products={products} />
        </Container>
      </Page>
    </ShopLayout>
  )
}

export default Shop

export const query = graphql`
  query ShopProducts {
    allProductsJson {
      edges {
        node {
          ...ProductList
        }
      }
    }
  }
`
